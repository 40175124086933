<template>
	<div>
		<van-search v-model="userName" placeholder="请输入员工名字搜索" class="searchPlace" @search="searchdata" @input="searchdata" background="#4988FF"></van-search>

		<div class="wrap">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh">
				<van-list v-model="loading" :finished="finished" @load="getData">
					<template v-if="list.length > 0">
						<van-cell-group v-for="(item, index) in list" :key="index">
							<van-cell>
								<template #title>
									<div class="title-div">
										<div class="day">
											<span>{{ getDate(item.logDate) }}</span>
											日
										</div>
										<div class="name" style="flex: 2">
											<p>{{ item.userName }}</p>
											<p>
												{{ formatTime(item.logDate, "YYYY-MM-DD") }}
												{{ getDayString(getDayOfTheWeek(item.logDate)) }}
											</p>
										</div>
										<div class="status">&nbsp;</div>
									</div>
								</template>
							</van-cell>

							<van-cell>
								<template #title>
									<div v-html="item.logContent" class="content"></div>
									<div class="submitTitle">{{item.submitTime ? item.submitTime+"编辑" : ''}}</div>
								</template>
							</van-cell>
						</van-cell-group>
					</template>

					<div class="empty" v-if="list.length == 0 && finished">
						<van-empty description="没有内容，空空如也~"
							:image="require('@/assets/img/mag_pic_noinformation.svg')"></van-empty>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import {
		formatTime,
		getDayOfTheWeek,
		getDate
	} from "@/utils/DateUtil";
	import {
		getEmpsList
	} from "@/api/user";

	const getDayString = (day) => {
		const days = {
			0: "星期天",
			1: "星期一",
			2: "星期二",
			3: "星期三",
			4: "星期四",
			5: "星期五",
			6: "星期六",
		};
		return days[day];
	};

	export default {
		data() {
			return {
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				size: 10,
				list: [],

				userName: "",
			};
		},
		components: {},
		watch: {},
		created() {},
		methods: {
			searchdata() {
				this.list = [];
                this.onRefresh();
				// this.page = 1;
				// this.list = [];
				// this.finished = false;
				// this.getData();
			},

			getData() {
				try {
					let params = {
						pageNo: this.page,
						pageSize: this.size,
						userName: this.userName,
					};
					getEmpsList(params).then((res) => {
						if (res.status == 200) {
							if (this.refreshing) {
								this.list = [];
								this.refreshing = false;
							}
							if (res.data && res.data.list.length > 0) {
								this.list = this.list.concat(res.data.list);
								this.page += 1;
							}
							this.loading = false;
							if (this.list.length >= res.data.total) {
								this.finished = true;
							}
						}
					});
				} catch (e) {
					this.loading = false;
					this.finished = true;
				}
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				this.loading = true;
				this.page = 1;
				// 重新加载数据
				this.getData();
			},
			formatTime,
			getDayOfTheWeek,
			getDayString,
			getDate,
		},
		computed: {
			// 从哪个页面跳转过来的
			isHasNav() {
				return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
			},
		},
	};
</script>

<style lang="less" scoped>
	.submitTitle {
		margin-left: 0.4rem;
		color: #ccc;
	}
	
	.searchPlace{
		/deep/.van-cell__value{
			display: flex;
			align-items: center;
		}
		/deep/.van-field__body{
                width:100%
            }
		
	}

	.wrap {
		padding: 0 10px;

		.title {
			display: flex;
			align-items: center;

			.line {
				width: 0.2rem;
				height: 0.8rem;
				background: #037ae7;
				margin-right: 10px;
			}
		}

		.title-div {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.day {
				margin-right: 16px;

				span {
					font-size: 42px;
					color: #4988ff;
				}
			}

			.name {
				flex: 2;

				p {
					margin: 0;
				}

				p:nth-child(1) {
					color: #231815;
					font-size: 16px;
					font-weight: 500;
				}

				p:nth-child(2) {
					color: #5F5F5F;
					font-size: 14px;
				}
			}

			.status {}
		}

		/deep/ .content {
			padding: 12px 0;

			p {
				margin: 0;
			}
		}

		/deep/ .blue .van-cell__value {
			color: #037ae7;
		}

		/deep/ .red .van-cell__value {
			color: rgb(255, 77, 79);
		}

		/deep/ .van-cell-group {
			margin: 12px 0;
		}
	}
</style>